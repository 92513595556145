import React, { Component } from "react";
import PropTypes from "prop-types";

import FormBuilder from "./FormBuilder";
import CloudPathInput from "./CloudPathInput";

const toVariableName = (str) =>
  str
    .toLowerCase()
    .replace(/[^a-z_ ]/g, "")
    .trim()
    .replace(/\s+/g, "_");

export class UploadingPanel extends Component {
  renderCloudDestination() {
    const { onChange, page, user } = this.props;
    const { cloud_destination } = page;

    const { active_plan } = user;
    const canUseGdrive = !!active_plan.gdrive;
    const linkedDropbox = !!(user.dropbox && user.dropbox.access_token);
    const linkedGdrive = !!(user.gdrive && user.gdrive.refresh_token);

    const onInputChange = (e) =>
      onChange({ cloud_destination: e.target.value });

    return (
      <div className="form-group">
        <label className="control-label" htmlFor="cloud_destination">
          File destination
        </label>
        <div className="radio">
          <label>
            <input
              type="radio"
              name="cloud_destination"
              value="email"
              checked={cloud_destination === "email"}
              onChange={onInputChange}
              disabled={false}
            />
            Email
          </label>
        </div>
        <div className="radio">
          <label>
            <input
              type="radio"
              name="cloud_destination"
              value="dropbox"
              checked={cloud_destination === "dropbox"}
              onChange={onInputChange}
              disabled={!linkedDropbox}
            />
            Dropbox
          </label>
          {linkedDropbox || (
            <span className="help-block">
              <a href="/account/apps">Link Dropbox</a> to send files from this
              page to Dropbox.
            </span>
          )}
        </div>
        <div className="radio">
          <label>
            <input
              type="radio"
              name="cloud_destination"
              value="gdrive"
              checked={cloud_destination === "gdrive"}
              onChange={onInputChange}
              disabled={!canUseGdrive || !linkedGdrive}
            />
            Google Drive{" "}
          </label>
          {canUseGdrive || (
            <span className="help-block">
              <a href="/account/billing">Upgrade</a> to send files from this
              page to Google Drive.
            </span>
          )}
          {linkedGdrive || (
            <span className="help-block">
              <a href="/account/apps">Link Google Drive</a> to send files from
              this page to Google Drive.
            </span>
          )}
        </div>
      </div>
    );
  }

  renderFolderPath() {
    const { onChange, page, user } = this.props;
    const { folder_path, cloud_destination } = page;
    const { dropbox_full_access } = user;

    const { active_plan } = user;
    const canChangeFolderPath = !!active_plan.change_folder_path;

    if (cloud_destination === "email") return;

    return (
      <div className="form-group">
        <label className="control-label" htmlFor="page_folder_path">
          Folder path
        </label>

        {cloud_destination === "dropbox" && (
          <div>
            <CloudPathInput
              // appKey={"ribh7ft60gym2l8"}
              // appKey={"8erxeyg6mr8lbyn"}
              // accessToken={user.dropbox.access_token}
              dropbox={user.dropbox}
              gdrive={user.gdrive}
              value={folder_path}
              onChange={(folder_path) => onChange({ folder_path })}
              prependPath={
                dropbox_full_access ? "Dropbox/" : "Dropbox/Apps/dbinbox"
              }
              disabled={!canChangeFolderPath}
              // cancel={() => console.log("canceled")}
              // multiselect={false}
              // extensions={["Directory"]}
              // folderselect={true}
            />
            {canChangeFolderPath && (
              <span className="help-block">
                What folder in your Dropbox should every file get uploaded
                inside of?
              </span>
            )}
            {canChangeFolderPath || (
              <span className="help-block">
                <a href="/account/billing">Upgrade</a> to move the folder in
                your Dropbox where every file is uploaded.
              </span>
            )}
          </div>
        )}

        {cloud_destination === "gdrive" && (
          <div>
            <div className="input-group">
              <span className="input-group-addon">Google Drive/</span>
              <input
                type="text"
                className="form-control"
                onChange={(e) => onChange({ folder_path: e.target.value })}
                value={folder_path || ""}
                disabled={!canChangeFolderPath}
              />
            </div>

            {canChangeFolderPath && (
              <span className="help-block">
                What folder in your Google Drive should every file get uploaded
                inside of?
              </span>
            )}
            {canChangeFolderPath || (
              <span className="help-block">
                <a href="/account/billing">Upgrade</a> to move the folder in
                your Google Drive where every file is uploaded.
              </span>
            )}
          </div>
        )}
      </div>
    );
  }

  renderResponseFormat() {
    const { onChange, page } = this.props;
    const { question_response_format } = page;

    return (
      <div className="form-group">
        <label
          className="control-label"
          htmlFor="page_question_response_format"
        >
          Answer Upload Format
        </label>
        <select
          className="form-control"
          id="page_question_response_format"
          value={question_response_format || ""}
          onChange={(e) =>
            onChange({ question_response_format: e.target.value })
          }
        >
          <option value="">don't upload answers</option>
          <option value="csv">answers.csv (excel file)</option>
          <option value="json">answers.json</option>
        </select>
      </div>
    );
  }

  renderFilePath() {
    const { onChange, page, user } = this.props;
    const { file_path, folder_path, questions, cloud_destination } = page;
    const { dropbox_full_access } = user;

    if (cloud_destination === "email") return;

    const { active_plan } = user;
    const canChangeFilePath = !!active_plan.change_file_path;

    let variables = questions
      .filter((q) => !!q.required)
      .map((q) => toVariableName(q.name));
    variables.push("timestamp");
    // console.log({ questions, variables });

    let prependPath = "";
    if (cloud_destination === "dropbox") {
      prependPath = dropbox_full_access ? "Dropbox/" : "Dropbox/Apps/dbinbox/";
    } else if (cloud_destination === "gdrive") {
      prependPath = "Google Drive/";
    }

    return (
      <div className="form-group">
        <label className="control-label" htmlFor="page_file_path">
          File path
        </label>
        <div className="input-group">
          <span className="input-group-addon">
            {prependPath}
            {folder_path}
          </span>
          <input
            placeholder="ex: {{timestamp}}/"
            className="form-control"
            type="text"
            value={file_path || ""}
            onChange={(e) => onChange({ file_path: e.target.value })}
            id="page_file_path"
            disabled={!canChangeFilePath}
          />
          <span className="input-group-addon">/filename</span>
        </div>
        {canChangeFilePath ? (
          <span className="help-block">
            dynamically organize uploader's files based on their answers.
          </span>
        ) : (
          <span className="help-block">
            <a href="/account/billing">Upgrade</a> to dynamically organize
            uploader's files based on their answers.
          </span>
        )}
        <span className="help-block">
          Variables:
          {variables.map((v) => (
            <button
              key={v}
              className="btn btn-default btn-xs"
              draggable="true"
              onClick={(e) => {
                e.preventDefault();
                onChange({ file_path: (file_path || "") + `{{${v}}}` });
              }}
              onDragStart={(e) => {
                console.log(e);
                e.dataTransfer.setData("text/plain", `{{${v}}}`);
              }}
              disabled={!canChangeFilePath}
            >
              {v}
            </button>
          ))}
        </span>
      </div>
    );
  }

  render() {
    const { page, user, onChange } = this.props;
    const { questions } = page;

    const { active_plan } = user;
    const maxQuestions = active_plan.max_uploader_questions;

    return (
      <div>
        <div
          className="alert alert-warning"
          role="alert"
          style={{ marginBottom: "10px" }}
        >
          If you’d like help setting this up for you, we’d love to help! Just
          send an email to{" "}
          <a href="mailto:support@fileinbox.com">support@fileinbox.com</a>.
        </div>

        <div className="well">
          <h4 style={{ marginTop: 0 }}>Uploader Questions</h4>
          <p>
            Ask your uploaders for their email address, name, or other personal
            data. This data can be used to sort their uploaded files
            automatically into folders.
          </p>
          <FormBuilder
            form={questions}
            onChange={(questions) => onChange({ questions })}
            maxQuestions={maxQuestions}
          />
          <hr />
          {this.renderResponseFormat()}
        </div>

        {this.renderCloudDestination()}
        {this.renderFolderPath()}
        {this.renderFilePath()}
      </div>
    );
  }
}

// https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
UploadingPanel.propTypes = {
  page: PropTypes.object,
  onChange: PropTypes.func,
};

export default UploadingPanel;
