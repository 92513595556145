import React, { Component } from "react";
import { Dropbox } from "dropbox";

import FileIcon from "../FileIcon";

const placeholderEntries = [
  {
    ".tag": "folder",
    name: "cgenco",
    path_lower: "/cgenco",
    path_display: "/cgenco",
    id: "id:6KZ_H8qqdJ0AAAAAAAAplw",
    tag: "folder",
  },
  {
    ".tag": "folder",
    name: "2017-10-08 05.33.49.81",
    path_lower: "/2017-10-08 05.33.49.81",
    path_display: "/2017-10-08 05.33.49.81",
    id: "id:6KZ_H8qqdJ0AAAAAAAAsxA",
    tag: "folder",
  },
  {
    ".tag": "file",
    name: "dbinboxtest2.txt",
    path_lower: "/dbinboxtest2.txt",
    path_display: "/dbinboxtest2.txt",
    id: "id:6KZ_H8qqdJ0AAAAAAAAsvw",
    client_modified: "2017-10-03T01:21:09Z",
    server_modified: "2017-10-03T01:21:10Z",
    rev: "7cb609b7b2e1",
    size: 403,
    content_hash:
      "a1b131dbbe82aedad91b32f89ec8a3bfae17132abc27dfae6f15b7e32cdbdf8d",
    tag: "file",
  },
];

const ErrorMessage = ({ error }) => {
  return (
    <div className="alert alert-danger" role="alert">
      <i className="fa fa-exclamation-circle" />{" "}
      <span className="sr-only">Error:</span>
      {error?.error ?? error?.message}
    </div>
  );
};

export class CloudPathInput extends Component {
  constructor(props) {
    super(props);
    this.dbx = new Dropbox({
      accessToken: props.dropbox.access_token.access_token,
      fetch: window.fetch.bind(window),
    });
  }

  state = {
    choosing: false,
    path: this.props.value || "",
    children: {},
  };

  // TODO: this doesn't cancel on unmount, so it's a memory leak right now
  refreshChildren() {
    const { path } = this.state;

    // cache folder data
    if (this.state.children[path]) return;

    this.dbx
      .filesListFolder({ path: path.replace(/^\/$/, "") })
      .then(({ result }) => {
        const entries = result.entries.map(entry => ({
          ...entry,
          tag: entry[".tag"],
        }));

        this.setState(({ children }) => ({
          children: { ...children, [path]: { entries } },
        }));
      })
      .catch(error => {
        this.setState(({ children }) => ({
          children: { ...children, [path]: { error } },
        }));
      });
  }

  componentDidMount() {
    this.refreshChildren();
  }

  renderInput = () => {
    const { value, prependPath, disabled = false } = this.props;

    return (
      <div className="input-group">
        <span className="input-group-addon">{prependPath}</span>
        <input
          type="text"
          className="form-control"
          disabled
          value={value || ""}
        />
        <span className="input-group-btn">
          <button
            className="btn btn-default"
            type="button"
            onClick={e => {
              e.preventDefault();
              this.setState({ choosing: true });
            }}
            disabled={disabled}
          >
            choose folder
          </button>
        </span>
      </div>
    );
  };

  renderChooserBreadcrumbs = () => {
    const { prependPath } = this.props;

    const { path } = this.state;
    let prefixedPath = "";
    let breadcrumbs = prependPath
      .split("/")
      .filter(p => p)
      .map(p => ({ name: p }));
    // only reset to root on the last breadcrumb
    // then we can have a special messge for crumbs missing a path
    breadcrumbs[breadcrumbs.length - 1].path = "/";

    breadcrumbs = [
      ...breadcrumbs,
      ...path
        .replace(/^\/$/, "")
        .split("/")
        .slice(1)
        .map(p => {
          prefixedPath += "/" + p;
          return { name: p, path: prefixedPath };
        }),
    ];

    return (
      <ol className="breadcrumb" style={{ marginBottom: 0 }}>
        {breadcrumbs.map(({ name, path }, i) => (
          <li key={i} className={i === breadcrumbs.length - 1 ? "active" : ""}>
            {i === breadcrumbs.length - 1 ? (
              name
            ) : (
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  if (path) this.setState({ path }, this.refreshChildren);
                  else
                    window.notice({
                      title: "Oops - can't do that yet",
                      body: "To upload files outside of the Dropbox/Apps/dbinbox folder, visit <a href='/account/'>your Account page</a> and turn on Full Dropbox access.",
                    });
                }}
              >
                {name}
              </a>
            )}
          </li>
        ))}
      </ol>
    );
  };
  renderChooser = () => {
    const { onChange, value } = this.props;
    const { path, children } = this.state;
    const contents = children[path] || {};

    let { entries, error } = contents;
    let loading = false;

    if (entries === undefined) {
      loading = true;
      entries = placeholderEntries;
    }

    return (
      <div className="panel panel-default">
        <div className="panel-heading" style={{ padding: 0 }}>
          <button
            onClick={e => {
              e.preventDefault();
              // TODO: possible race condition? weird state?
              onChange(path);
              this.setState({ choosing: false });
            }}
            className="btn btn-primary pull-right"
            disabled={path === value}
          >
            set folder path
          </button>
          <button
            onClick={e => {
              e.preventDefault();
              this.setState({ choosing: false, path: value ?? "" });
            }}
            className="btn btn-default pull-right"
          >
            cancel
          </button>
          {this.renderChooserBreadcrumbs()}
        </div>
        {error && <ErrorMessage error={error} />}
        {entries.length === 0 && (
          <div className="panel-body">
            <i>This folder is empty</i>
          </div>
        )}
        {!error && (
          <div
            className={`list-group ${loading ? "loading" : ""}`}
            style={{ maxHeight: 310, overflow: "scroll" }}
          >
            {entries.map(({ name, id, path_display, tag }) => (
              <button
                key={id}
                disabled={loading || tag !== "folder"}
                type="button"
                className={`list-group-item ${
                  loading || tag !== "folder" ? "disabled" : ""
                }`}
                onClick={e => {
                  e.preventDefault();
                  this.setState({ path: path_display }, this.refreshChildren);
                }}
              >
                <FileIcon filename={name} isDirectory={tag === "folder"} />{" "}
                {name}
              </button>
            ))}
          </div>
        )}
        {/* <div
        className="panel-footer"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <button className="btn btn-default">cancel</button>
        <button className="btn btn-primary">choose "{path}"</button>
      </div> */}
        {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify({ path }, null, 2)}</pre> */}
      </div>
    );
  };

  render() {
    const {
      dropbox: { access_token },
    } = this.props;
    const { choosing } = this.state;

    if (!access_token)
      return (
        <div>
          <p>
            It looks like your Fileinbox account isn't connected to a Dropbox
            account. <a href="/account/apps">Link your Dropbox account</a>{" "}
            first, then come back here to change which folder files are uploaded
            to.
          </p>
        </div>
      );

    return choosing ? this.renderChooser() : this.renderInput();
  }
}

// https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
CloudPathInput.propTypes = {};
export default CloudPathInput;
